import React, { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { getStatusSubStatus, saveNote } from "../../api/functionApi"
import { resetMsg, setTransactionStatusNoteParameters } from "../../actions/functionActions"
import Formsy from "formsy-react"
import { find, groupBy, isNil, result, toPairs } from "lodash"
import DatePicker from "react-datepicker"
import moment from "moment-timezone"
import { showToastSuccessMessage } from "../../api/toasterApi"
import ValidatingInput from "../../components/common/ValidatingInput"
import StatusSelect from "./statusSelect"
import SubStatusSelect from "./subStatusSelect"
import { Loading, useClient, useInvevoSession } from "invevo-react-components"
import { getFocusedAccount } from "../../api/currentSelectionApi"
import classes from "./TransactionStatus.module.scss"
import { useCustomer } from "../../../../contexts/CustomerContext"
import { useSelectedEntities } from "../../../../contexts/SelectedEntitiesContext"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"
import { useEntity } from "../../../../contexts/EntityContext"
import { useEvent } from "../../../../contexts/EventContext"

const TransactionStatus = ({
    promiseToPayDate,
    statusId,
    subStatusId,
    statusSubStatus,
    msg,
    errMsg,
    reportingContact,
    reportingNotes,
    resetMsg,
    selectedAccount,
    allTransactionsSelected,
    setTransactionStatusNoteParameters,
    notes,
    formInputsCanSave,
    transactionsAggregate,
    saveNote,
    getStatusSubStatus
}) => {
    const client = useClient()
    const currentTransactionStatusNote = {
        statusId,
        subStatusId,
        ptpDate: promiseToPayDate,
        notes,
        reportingNotes,
        reportingContact,
        formInputsCanSave
    }
    const { isEnabled: useEntityStructure } = useFeatureToggle("newEntityStructure")
    const { isEnabled: useEntityDashboards } = useFeatureToggle("useEntityDashboardsWhenSqlFirst")
    const { isEnabled: savePaymentPromiseInUtc } = useFeatureToggle("savePaymentPromiseInUtc")
    const shouldUseEntityTransactions = useEntityStructure && useEntityDashboards

    const [blocked, setBlocked] = useState(false)
    const [showDate, setShowDate] = useState(false)
    const [transactionRefs, setTransactionRefs] = useState([])
    const [selectedStatus, setSelectedStatus] = useState()
    const [selectedSubStatus, setSelectedSubStatus] = useState()
    const [invevoSession, setInvevoSession] = useInvevoSession()
    const [customer] = useCustomer()
    const { selectedEntity, selectedRefs } = useSelectedEntities()
    const [entity] = useEntity()
    const { triggerEvent } = useEvent()

    const cancel = useCallback(() => {
        setTransactionStatusNoteParameters({
            statusId: null,
            subStatusId: null,
            ptpDate: null,
            notes: "",
            reportingNotes: "",
            reportingContact: "",
            formInputsCanSave: true
        })
        setShowDate(false)
    }, [setTransactionStatusNoteParameters])

    useEffect(() => {
        if (shouldUseEntityTransactions) {
            if (selectedRefs.transaction !== transactionRefs) {
                setTransactionRefs(selectedRefs.transaction ?? [])
            }
            return
        }

        if (!customer.selectedTransactions) {
            return
        }

        setTransactionRefs(customer.selectedTransactions.map(t => t.transactionRef))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer.selectedTransactions, selectedRefs.transaction])

    useEffect(() => {
        resetMsg()
        if (isNil(statusSubStatus) || statusSubStatus.length === 0) {
            getStatusSubStatus(client)
        }

        return () => cancel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (msg === "saved") {
            showToastSuccessMessage("Updated successfully")
            cancel()
            resetMsg()
        }
    }, [msg, errMsg, blocked, cancel, resetMsg])

    const setParams = (newStatusId, newSubStatusId, newPtpDate) => {
        const selectedStatus = result(
            find(statusSubStatus, t => t.id === newStatusId),
            "status"
        )
        const selectedSubStatus = result(
            find(statusSubStatus, t => t.subStatusId === newSubStatusId),
            "subStatus"
        )

        const dateText = newPtpDate ? moment(newPtpDate).format("Do MMM YYYY") : ""

        setTransactionStatusNoteParameters({
            ...currentTransactionStatusNote,
            statusId: newStatusId,
            subStatusId: newSubStatusId,
            ptpDate: newPtpDate,
            notes: `Status ${selectedStatus}` + (selectedSubStatus ? ` / ${selectedSubStatus}` : "") + ` ${dateText}`
        })
    }

    const changePTPDate = date => {
        const ptpDate = moment(date).isDST() ? moment(date).add(1, "hour").utc().format() : moment(date).utc().format()
        setParams(statusId, subStatusId, ptpDate)
    }

    const notesChange = event => {
        const notes = event.target.value

        setTransactionStatusNoteParameters({
            ...currentTransactionStatusNote,
            notes
        })
    }

    const reportingContactChange = event => {
        const reportingContact = event.target.value

        setTransactionStatusNoteParameters({
            ...currentTransactionStatusNote,
            reportingContact
        })
    }

    const reportingNotesChange = event => {
        const reportingNotes = event.target.value

        setTransactionStatusNoteParameters({
            ...currentTransactionStatusNote,
            reportingNotes
        })
    }

    const enableFormInputSave = () => {
        setTransactionStatusNoteParameters({
            ...currentTransactionStatusNote,
            formInputsCanSave: true
        })
    }

    const disableFormInputSave = () => {
        setTransactionStatusNoteParameters({
            ...currentTransactionStatusNote,
            formInputsCanSave: false
        })
    }

    const onSubmit = () => {
        setBlocked(true)

        const literalDate = moment(promiseToPayDate).format("YYYY-MM-DD")
        const utcMidnightAtSelectedDate = moment.tz(literalDate, "UTC").toISOString()

        const dateToUse = savePaymentPromiseInUtc ? utcMidnightAtSelectedDate : promiseToPayDate

        const { miaAccountIsVirtualAccount, miaAccountId } = selectedAccount
        const allTrSelected = miaAccountIsVirtualAccount ? false : allTransactionsSelected
        const noteText = notes
            ? notes
            : reportingContact && reportingNotes
            ? "Reporting contact and note added."
            : reportingContact
            ? "Reporting contact added."
            : reportingNotes
            ? "Reporting note added."
            : ""

        const requests = (
            shouldUseEntityTransactions
                ? [[entity.reference, transactionRefs.map(t => ({ transactionRef: t }))]]
                : toPairs(groupBy(customer.selectedTransactions, "customerRef"))
        ).map(customerTransactions => {
            const customerId =
                shouldUseEntityTransactions && selectedEntity.entityTypeReference === "customer"
                    ? entity.sqlId
                    : invevoSession.virtualAccountCustomers?.find(c => c.accountCode === customerTransactions[0])?.customerId || miaAccountId

            return saveNote(client, {
                noteTypeId: 2,
                noteText: noteText,
                noteTypeKeysId: customerTransactions[1].map(t => t.transactionRef),
                allTrSelected,
                followUpDate: null,
                ptpDate: dateToUse,
                statusId,
                subStatusId,
                contactId: null,
                GroupId: null,
                comments: reportingNotes,
                customerContact: reportingContact,
                isVirtualAccount: miaAccountIsVirtualAccount && customerId === miaAccountId,
                customerId: customerId,
                useNewEndpoint: true
            })
        })

        Promise.all(requests).then(() => {
            setBlocked(false)

            setInvevoSession({
                stateDeltas: {
                    transactions: transactionRefs.map(t => {
                        return {
                            transactionRef: t,
                            ...(selectedStatus && { status: selectedStatus?.code?.toLowerCase() }),
                            ...(selectedSubStatus && { substatus: selectedSubStatus?.code?.toLowerCase() }),
                            ...(promiseToPayDate && { paymentpromisedate: dateToUse }),
                            ...(reportingNotes && { comments: reportingNotes }),
                            ...(reportingContact && { customercontact: reportingContact })
                        }
                    })
                }
            })

            triggerEvent("reload_transaction", {
                transactionReferences: transactionRefs,
                status: selectedStatus?.code?.toLowerCase(),
                subStatus: selectedSubStatus?.code?.toLowerCase(),
                paymentPromiseDate: moment(dateToUse)?.valueOf().toString()
            })
        })
    }

    const canSaveForm = () => {
        if (shouldUseEntityTransactions && !["customer", "virtual_parent"].includes(selectedEntity?.entityTypeReference)) return false
        if (!statusSubStatus || !formInputsCanSave) return false

        let valid = false

        const hasStatus = statusId > 0
        const hasRequiredFields = subStatusId !== null && notes.length > 0
        const isMultiCurrency = transactionsAggregate && transactionsAggregate.CurrencyCodes && transactionsAggregate.CurrencyCodes.length > 1
        const hasValidDate = showDate === false || promiseToPayDate || isMultiCurrency

        const hasValidNote = notes.length > 0 || reportingNotes.length > 0 || reportingContact.length > 0
        if (hasStatus && hasRequiredFields && hasValidDate) {
            valid = true
        } else if (!hasStatus) {
            valid = hasValidNote
        }

        return valid
    }
    return (
        <div className={`d-flex flex-column flex-grow-1 ${classes.container}`}>
            <Loading isLoading={blocked}>
                <div className=" top20">
                    <h2 className="text-white">Transaction Update</h2>
                </div>

                <hr />

                {transactionRefs.length === 0 ? (
                    <h4 className="text-white">Select transactions for update</h4>
                ) : (
                    <Formsy onValid={enableFormInputSave} onInvalid={disableFormInputSave}>
                        <div className={`${classes["form-row"]} align-items-center`}>
                            <div className="col-4">
                                <h3 className="text-white">Status</h3>
                            </div>
                            <div className="col-8" aria-label="status-dropdown">
                                <StatusSelect
                                    id={statusId}
                                    onChange={(statusId, showDate, status) => {
                                        setParams(statusId, null, null)
                                        setShowDate(showDate)
                                        setSelectedStatus({ ...status })
                                    }}
                                />
                            </div>
                        </div>

                        <div className={`${classes["form-row"]} align-items-center top10`}>
                            <div className="col-4">
                                <h3 className="text-white">Substatus</h3>
                            </div>
                            <div className="col-8" aria-label="sub-status-dropdown">
                                <SubStatusSelect
                                    id={subStatusId}
                                    statusId={statusId}
                                    onChange={(subStatusId, subStatus) => {
                                        setParams(statusId, subStatusId, null)
                                        setSelectedSubStatus(subStatus)
                                    }}
                                />
                            </div>
                        </div>

                        <hr />

                        {showDate && (
                            <>
                                <div className="form-group">
                                    <DatePicker
                                        name="ptpDate"
                                        className="form-control"
                                        dateFormat="dd MMMM yyyy"
                                        selected={promiseToPayDate}
                                        placeholderText="Status date"
                                        onChange={changePTPDate}
                                    />
                                </div>
                                <hr />
                            </>
                        )}

                        <div className="form-group">
                            <label htmlFor="statusUpdateNotes">Notes</label>
                            <textarea
                                className="form-control"
                                rows="4"
                                name="statusUpdateNotes"
                                value={notes}
                                placeholder="Notes"
                                onChange={notesChange}
                                aria-label="notes"
                            />
                        </div>

                        <hr />

                        <ValidatingInput
                            name="reportingContact"
                            title="Reporting Contact"
                            value={reportingContact}
                            validations={{ minLength: 1, maxLength: 50 }}
                            validationError="You must enter a valid reporting contact."
                            onChange={reportingContactChange}
                        />
                        <hr />
                        <div className="form-group">
                            <label htmlFor="reportingNotes">Reporting Notes</label>
                            <textarea
                                className="form-control"
                                rows="4"
                                name="reportingNotes"
                                maxLength="500"
                                value={reportingNotes}
                                placeholder="Reporting Notes"
                                onChange={reportingNotesChange}
                            />
                        </div>
                        <hr />
                        <div className="form-group px-2 d-flex justify-content-between">
                            <button type="button" onClick={onSubmit} className={`btn ${classes["btn-custom"]}`} disabled={!canSaveForm()}>
                                Update
                            </button>
                            <button type="button" className={`btn ${classes["btn-custom"]}`} onClick={cancel}>
                                Cancel
                            </button>
                        </div>
                    </Formsy>
                )}
            </Loading>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        statusSubStatus: state.functionReducer.statusSubStatus,
        allTransactionsSelected: state.functionReducer.allTransactionsSelected,
        promiseToPayDate: state.functionReducer.ptpDate ? new Date(state.functionReducer.ptpDate) : null,
        statusId: state.functionReducer.statusId,
        subStatusId: state.functionReducer.subStatusId,
        msg: state.functionReducer.msg,
        errMsg: state.functionReducer.errMsg,
        selectedAccount: state.currentSelectionReducer.selectedAccount,
        notes: state.functionReducer.notes,
        reportingNotes: state.functionReducer.reportingNotes,
        reportingContact: state.functionReducer.reportingContact,
        formInputsCanSave: state.functionReducer.formInputsCanSave,
        transactionsAggregate: state.functionReducer.transactionsAggregate
    }
}

export default connect(mapStateToProps, {
    saveNote,
    setTransactionStatusNoteParameters,
    getStatusSubStatus,
    resetMsg,
    getFocusedAccount
})(TransactionStatus)
